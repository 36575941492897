import React from 'react'

const Footer = () => {
  return (
    <footer
      style={{ minHeight: '50px', background: '#f7f7f7' }}
      className='w-100 border-top border-bottom mt-auto d-flex justify-content-center align-items-center'
    >
      <p className='m-0 text-center'>&copy; 2022 Kamil Wójtowicz</p>
    </footer>
  )
}

export default Footer
