import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type PersistedState<T> = [T, Dispatch<SetStateAction<T>>]

function usePersistedState<T>(defaultValue: T, key: string): PersistedState<T> {
  const [value, setValue] = useState<T>(() => {
    const value = window.localStorage.getItem(key)

    return value ? (JSON.parse(value) as T) : defaultValue
  })

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

export { usePersistedState }
